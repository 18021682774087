window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.mask = require('inputmask');

    window.Chart = require('chart.js');
    window.Quill = require('quill');
    window.moment = require('moment');
    require('bootstrap');
    const {Sortable} = require('sortablejs');
    window.Sortable = Sortable;
    window.$.datetimepicker = require('pc-bootstrap4-datetimepicker/build/js/bootstrap-datetimepicker.min');
    require('jquery.easing');
    require('jquery-toast-plugin');
    require('@fortawesome/fontawesome-free/js/all.js');
    window.PDFObject = require('pdfobject');
    // $(document).ready(function () {
    //     window.mask('99999-999').mask($('.cep'));
    //     window.mask('(99) 9999[9]-9999', {skipOptionalPartCharacter: ' ', greedy: false}).mask($('.telefone'));
    //     $('#btn-menu-mobile').on('click', function () {
    //         $('#menu-mobile').show();
    //     });
    //     $('#hide-menu-mobile').on('click', function () {
    //         $('#menu-mobile').hide();
    //     });
    //     $("#menu-toggle").click(function (e) {
    //         e.preventDefault();
    //         $("#wrapper").toggleClass("toggled");
    //     });
    //
    //     $('#nav-wrapper').height($("#nav").height());
    //     $('#nav').affix({
    //         offset: {top: $('#nav').offset().top}
    //     });
    //
    //     $('a.page-scroll').bind('click', function(event) {
    //         var $anchor = $(this);
    //         $('html, body').stop().animate({
    //             scrollTop: $($anchor.attr('href')).offset().top
    //         }, 1500, 'easeInOutExpo');
    //         event.preventDefault();
    //     });
    //
    //     // Highlight the top nav as scrolling occurs
    //     $('body').scrollspy({
    //         target: '.navbar-fixed-top'
    //     })
    //
    //     // Closes the Responsive Menu on Menu Item Click
    //     $('.navbar-collapse ul li a').click(function() {
    //         $('.navbar-toggle:visible').click();
    //     });
    // });

} catch (e) {
    console.log('error', e);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';
// window.io = require('socket.io-client');

// window.Pusher = require('pusher-js');
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true,
//     forceTLS: true
// });

// window.Echo = new Echo({
//     broadcaster: 'socket.io',
//     host: window.location.hostname + ":" + process.env.MIX_LARAVEL_ECHO_PORT
// });
